import { TFunction } from "react-i18next";

import { InfographicTransferData } from "../../../types/forwarding/trello";
import { toFormattedDate } from "../../../utils/common/date";
import { InfographicTransferDate, InfographicTransferShipment } from "../types";

import { ShipdaCurrentLanguage } from "../../../i18n/i18nForShipda";

/**
 * 시간 데이터를 생성하는 함수
 *
 * 얼마나 걸리는지 날짜를 가공하는 데이터는 remainedDays 값만 삽입
 */
export function createTimeTaken(
  remainedDays: number
): InfographicTransferShipment {
  return {
    shipName: "",
    voyageNumber: "",
    ATA: null,
    ETA: null,
    ATD: null,
    ETD: null,
    port: {
      code: "",
      id: 0,
      name: "",
      nameEN: "",
    },
    transportType: "sea",
    remainedDays,
  };
}

/**
 * 출발지-환적지-도착지 각 선적 일정을 YYYY-MM-DD 포맷으로 리턴
 *
 * 전부다 없는 경우 문자열 '' 리턴
 *
 * @example
 * 우선순위
 * 1. ATA
 * 2. ETA
 * 3. ATD
 * 4. ETD
 */
export function getTransferShipmentDate({
  ATA,
  ETA,
  ATD,
  ETD,
}: InfographicTransferDate): string {
  // TODO: toFormattedDate > 날짜 연산에 사용되는 포맷은 수정 보류
  return (
    toFormattedDate(ATA, "YYYY-MM-DD") ||
    toFormattedDate(ETA, "YYYY-MM-DD") ||
    toFormattedDate(ATD, "YYYY-MM-DD") ||
    toFormattedDate(ETD, "YYYY-MM-DD") ||
    ""
  );
}

/**
 * 쉽다 KR에서 YY.MM.DD 형식으로 포매팅된 날짜를 계산을 위해 2025-02-09 형식으로 변환 (dayjs에서 YY.MM.DD 타입은 날짜 계산을 할 수 없음)
 *
 * "YY. MM. DD." → "YYYY-MM-DD"
 */
export const convertToCalculableDate = (date: string): string => {
  // 쉽다 영문의 경우 그대로 노출시킴
  if (ShipdaCurrentLanguage.currentLanguage === "en") return date;

  // FIXME: 고정으로 '20'이라는 문자열을 넣어주고 있어서 수정필요
  return date
    ? `20${date
        .split(".")
        .slice(0, 3)
        .map((s) => s.trim().padStart(2, "0"))
        .join("-")}`
    : "";
};

/**
 * 인포그래픽 > 현재 운송 모선명을 찾는 유틸함수
 * 'LTS'는 내륙 이벤트로 '내륙 운송 중'을 노출함 (#15299)
 *
 * @param t 쉽다에서 다국어처리를 위해 전달, 쉽다 Admin은 전달 X
 */
export function getTransportNameForInfographic(
  shipList: InfographicTransferData[],
  t?: TFunction
) {
  const currentShipName =
    shipList.find(({ shipNow }) => shipNow)?.shipName ?? "-";

  if (currentShipName === "LTS") {
    return t
      ? t("page-mypage-bid:운송관리_공통_상태_내륙운송중")
      : "내륙 운송 중";
  }

  return currentShipName;
}

/**
 * 인포그래픽 > 항차/편명을 찾는 유틸함수
 */
export function getTransportNumberForInfographic(
  shipList: InfographicTransferData[]
) {
  return shipList.find(({ shipNow }) => shipNow)?.voyageNo ?? "-";
}
