import styled, { css } from "styled-components";

import { TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

import { mobile } from "../../styles/functions";

const freightTypeInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  & > .info {
    display: flex;
    align-items: center;
    ${setFontStyle("Body3")};
    color: ${TEXT_COLOR.black_2};
    gap: 2px;

    .more-text {
      text-decoration: underline;
    }
  }

  & > .cautions {
    display: flex;
    align-items: center;
    gap: 2px;

    .title {
      ${setFontStyle("Body5")}
      color: ${TEXT_COLOR.black_2}
    }

    ${mobile(css`
      .title {
        ${setFontStyle("Body4")};
      }
    `)}
  }

  ${mobile(css`
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
  `)}
`;

export default { freightTypeInfo };
