/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  EffectCallback,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import {
  Cluster,
  GridAlgorithm,
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { createCustomEqual } from "fast-equals";

import { useCheckIsMobile } from "../../../../utils/common/hook";
import { getCombinedCenter, getPolylineCenter } from "./utils";

import { APP_NAME } from "../../../../constants";
import { MapProps } from ".";

// 전체 지도의 렌더링과 구성은 공식문서 참고
// https://developers.google.com/maps/documentation/javascript/react-map
export default function useGoogleMap({
  markerInfoList,
  polylineInfo,
  dashedPolylineInfo,
  setMarkerCluster,
  onGoogleApiLoaded,
  isAutoZoom,
  minZoom,
  boundsPadding,
  pinCenter,
  totalPolylineData,
  ...options
}: MapProps) {
  const { isMobile } = useCheckIsMobile();

  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  const [isMapLoading, setIsMapLoading] = useState(true);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [isMapLoadError, setIsMapLoadError] = useState<Error | null>(null);

  // 이전에 열린 infoWindow 가 닫히게 하기 위해 infoWindow 를 저장하는 변수
  let prevInfoWindow: google.maps.InfoWindow | undefined = undefined;

  const setInfoWindow = useCallback(
    ({
      infoWindow,
      content,
      map,
      marker,
    }: {
      infoWindow: google.maps.InfoWindow;
      content: string;
      map?: google.maps.Map;
      marker: google.maps.marker.AdvancedMarkerElement;
    }) => {
      if (!map) return;

      if (!content) return;

      if (prevInfoWindow) {
        prevInfoWindow.close();
      }

      infoWindow.setContent(content);

      google.maps.event.addListenerOnce(map, "idle", function () {
        infoWindow.open(map, marker);
      });

      prevInfoWindow = infoWindow;
    },
    []
  );

  // 좌표 값으로 깊은 비교
  const checkDeepEqualsForMaps = createCustomEqual(
    // fast-equals 라이브러리 때문에 나는 type error 무시
    // @ts-ignore
    (deepEqual) => (a: any, b: any) => {
      if (
        isLatLngLiteral(a) ||
        a instanceof google.maps.LatLng ||
        isLatLngLiteral(b) ||
        b instanceof google.maps.LatLng
      ) {
        return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
      }

      // @ts-ignore
      return deepEqual(a, b);
    }
  );

  const useDeepCompareMemoize = (value: unknown) => {
    const ref = useRef<unknown>();

    if (!checkDeepEqualsForMaps(value, ref.current)) {
      ref.current = value;
    }

    return ref.current;
  };

  const useDeepCompareEffectForMaps = (
    callback: EffectCallback,
    dependencies: unknown[]
  ) => {
    useEffect(callback, [...dependencies.map(useDeepCompareMemoize), callback]);
  };

  // 초기 center 계산
  useEffect(() => {
    if (!map) return;

    const initCenter = (() => {
      if (pinCenter) {
        return new google.maps.LatLng(pinCenter.lat, pinCenter.lng);
      }

      if (totalPolylineData) {
        return getPolylineCenter(totalPolylineData);
      }

      if (markerInfoList) {
        return getCombinedCenter(totalPolylineData ?? [], markerInfoList);
      }

      return new google.maps.LatLng(35, 125);
    })();

    map.setOptions({ ...options, center: initCenter });
  }, [map, markerInfoList, options, pinCenter, totalPolylineData]);

  // 깊은 비교를 해서 Map 을 리렌더링.
  // https://developers.google.com/maps/documentation/javascript/react-map#map-component-props
  // https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (!map) return;

    let markerClusterer: MarkerClusterer | null = null;
    const currentMarkers: google.maps.marker.AdvancedMarkerElement[] = [];

    const defaultClusteringGridAlgorithm = new SuperClusterAlgorithm({
      maxZoom: 6,
      radius: 120,
    });

    // 클러스터링 없이 marker 표기만 할때, grid 옵션의 maxZoom 을 0으로 표기.
    const noClusteringGridAlgorithm = new GridAlgorithm({
      maxZoom: 0,
    });

    const markers = markerInfoList?.map(
      (
        {
          lat,
          lng,
          label,
          iconInfo,
          infoWindowData,
          zIndex,
          pixelOffset,
          markerInfo,
          onClick,
          onClickOutSide,
          onMouseOver,
          onMouseOut,
        },
        i
      ) => {
        const position = { lat, lng };

        const getMarkerForClusterMap = () => {
          const markerContent = (
            <div
              style={{ position: "relative", width: "27px", height: "35px" }}
            >
              <svg
                width="27"
                height="35"
                viewBox="0 0 27 35"
                xmlns="http://www.w3.org/2000/svg"
                style={{ position: "absolute", top: 0, left: 0 }}
              >
                <path
                  d="M13.0478 0.25C20.0987 0.25 25.8454 6.11948 25.8454 13.345C25.8454 16.3198 24.8918 19.1233 23.0853 21.4596C23.0852 21.4597 23.0851 21.4599 23.085 21.46L13.0478 34.3677L3.0077 21.4572C1.20375 19.1236 0.25 16.32 0.25 13.345C0.25 6.11948 5.99679 0.25 13.0478 0.25Z"
                  fill="#D85140"
                  stroke="#B93B2E"
                  strokeWidth="0.5"
                />
              </svg>
              <div
                style={{
                  position: "absolute",
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: 600,
                  textAlign: "center",
                  top: "40%",
                  width: "27px",
                  lineHeight: "14px",
                  transform: "translateY(-50%)",
                }}
              >
                {label}
              </div>
            </div>
          );

          const markerElement = document.createElement("div");
          ReactDOM.render(markerContent, markerElement);

          return new google.maps.marker.AdvancedMarkerElement({
            map,
            position,
            content: markerElement,
            // Cluster에서 접근하기 위해 title을 설정
            title: label,
          });
        };

        const marker = setMarkerCluster
          ? getMarkerForClusterMap()
          : new google.maps.marker.AdvancedMarkerElement({
              position,
              content: markerInfo ? markerInfo : iconInfo?.svg,
              map,
              zIndex,
              collisionBehavior: google.maps.CollisionBehavior.REQUIRED,
              gmpDraggable: false,
            });

        if (onClick || onClickOutSide || onMouseOver || onMouseOut) {
          marker.element.classList.add("pointer-events-auto");
        }

        if (onClick) {
          marker.addListener("click", (e: google.maps.MapMouseEvent) => {
            e.stop();

            onClick(e);
          });
        }

        if (onClickOutSide) {
          google.maps.event.addListener(
            map,
            "click",
            (e: google.maps.MapMouseEvent) => {
              onClickOutSide(e);
            }
          );
        }

        if (onMouseOver) {
          marker.addEventListener("mouseover", (e) => {
            e.stopPropagation();

            onMouseOver(e);
          });
        }

        if (onMouseOut) {
          marker.addEventListener("mouseout", (e) => {
            e.stopPropagation();

            onMouseOut(e);
          });
        }

        // 마커에 나타나는 정보창
        const infoWindow = new google.maps.InfoWindow({
          content: "",
          disableAutoPan: true,
          zIndex,
          pixelOffset,
        });

        // 항시 보이는 타입
        if (infoWindowData?.type === "visible") {
          setInfoWindow({
            infoWindow,
            content: infoWindowData?.content,
            map,
            marker,
          });
        }

        // 클릭 했을 때에만 보이는 타입
        if (infoWindowData?.type === "click") {
          marker.addListener("click", () => {
            setInfoWindow({
              infoWindow,
              content: infoWindowData?.content,
              map,
              marker,
            });

            const markerPosition = marker.position;

            if (map && marker && markerPosition) {
              map.addListener("click", () => {
                infoWindow.close();
              });

              map.panTo(markerPosition);

              if (isMobile) {
                map.panBy(0, -70);
              }
            }
          });
        }

        currentMarkers.push(marker);
        return marker;
      }
    );

    // AdvancedMarkerElement에서 googlemaps/markerclusterer제공하는 defaultRenderer사용할 수 없어 커스텀 추가
    const customRenderer = {
      render: (cluster: Cluster) => {
        const color = cluster.count > 10 ? "#ff0000" : "#0000ff";
        const size = 45;

        const markerClusterLabel = setMarkerCluster
          ? setMarkerCluster(cluster).label
          : undefined;

        const clusterIcon = (
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <svg
              fill={color}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 240 240"
              width={size}
              height={size}
            >
              <circle cx="120" cy="120" opacity=".6" r="70" />
              <circle cx="120" cy="120" opacity=".3" r="90" />
              <circle cx="120" cy="120" opacity=".2" r="110" />
              <circle cx="120" cy="120" opacity=".1" r="130" />
            </svg>
            <div
              style={{
                position: "absolute",
                color: "rgba(255,255,255,0.9)",
                fontSize: "12px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {markerClusterLabel}
            </div>
          </div>
        );

        const clusterElement = document.createElement("div");
        ReactDOM.render(clusterIcon, clusterElement);

        return new google.maps.marker.AdvancedMarkerElement({
          position: cluster.position,
          content: clusterElement,
        });
      },
    };

    // Marker Clustering 설정
    // https://developers.google.com/maps/documentation/javascript/marker-clustering 참고
    markerClusterer = new MarkerClusterer({
      markers,
      map,

      algorithm: setMarkerCluster
        ? defaultClusteringGridAlgorithm
        : noClusteringGridAlgorithm,

      // https://googlemaps.github.io/js-markerclusterer/interfaces/MarkerClustererOptions.html
      renderer: customRenderer,
    });

    // Polyline 설정
    if (polylineInfo) {
      const shipPath = new google.maps.Polyline(polylineInfo);
      shipPath.setMap(map);
    }

    if (dashedPolylineInfo) {
      const dotPath = new google.maps.Polyline(dashedPolylineInfo);
      dotPath.setMap(map);
    }

    // 마커, 폴리라인 등 자동 줌 설정
    if (isAutoZoom && map) {
      const bounds = new google.maps.LatLngBounds();

      if (markers && markers.length > 0) {
        markers.forEach((marker) => {
          // 타입스크립트에서 marker.getPosition()을 undefined로 확인해 non-null 단언 연산자 추가
          if (marker && marker.position) {
            bounds.extend(marker.position);
          }
        });
      }

      if (totalPolylineData) {
        const polyline = new google.maps.Polyline(totalPolylineData);

        const paths = polyline.getPath().getArray();

        paths.forEach((latLng) => {
          bounds.extend(latLng);
        });
      }

      const currentCenter = map.getCenter();

      map.fitBounds(bounds, {
        top: boundsPadding?.top,
        bottom: boundsPadding?.bottom ?? 0,
        left: boundsPadding?.left,
        right: boundsPadding?.right ?? 0,
      });

      const newZoom = map.getZoom();

      if (currentCenter && typeof newZoom === "number") {
        map.setCenter(currentCenter);
        map.setZoom(newZoom - 1);
      }
    }

    return () => {
      currentMarkers.forEach((marker) => {
        marker.map = null;
      });

      if (markerClusterer) {
        markerClusterer.setMap(null);
      }
    };
  }, [map, markerInfoList, options]);

  useEffect(() => {
    if (!map) return;

    // 렌더링 된 지도를 바탕으로 마커와 지도 경계선과 마커 사이의 거리를 재계산
    google.maps.event.addListenerOnce(map, "tilesloaded", () => {
      const currentCenter = map.getCenter();
      const currentBounds = map.getBounds();

      if (currentBounds) {
        const boundsEast = currentBounds.getNorthEast().lng();

        let rightmostMarkerLng = -180;

        markerInfoList?.forEach((marker) => {
          if (marker && marker.lng) {
            rightmostMarkerLng = Math.max(rightmostMarkerLng, marker.lng);
          }
        });

        const distanceLng = Math.abs(boundsEast - rightmostMarkerLng);

        const boundsWidth = Math.abs(
          currentBounds.getNorthEast().lng() -
            currentBounds.getSouthWest().lng()
        );

        const maxWidth = 315;

        const distanceWidth = maxWidth - boundsWidth;

        if (distanceWidth < 30 && distanceLng < 55) {
          const newLng = 55 - distanceLng;

          map.setCenter({
            lat: currentCenter?.lat() ?? 0,
            lng: (currentCenter?.lng() ?? 0) + newLng,
          });
        }
      }
    });
  }, [map, markerInfoList, options]);

  // 지도 기초정보 렌더링에 필요한 부분
  // https://developers.google.com/maps/documentation/javascript/react-map#add-map
  useEffect(() => {
    if (ref.current && !map) {
      try {
        setIsMapLoading(true);

        const newMap = new window.google.maps.Map(ref.current, {
          mapId:
            APP_NAME === "shipda-admin"
              ? process.env.REACT_APP_PUBLIC_GOOGLE_MAP_ID_SILVER_THEME
              : process.env.NEXT_PUBLIC_GOOGLE_MAP_ID_SILVER_THEME,
          // 지도 반복 방지를 위한 옵션
          restriction: {
            latLngBounds: {
              north: 85,
              south: -85,
              west: -180,
              east: 180,
            },
            strictBounds: true,
          },
          minZoom,
          cameraControl: false,
          zoomControl: true,
        });

        google.maps.event.addListenerOnce(newMap, "idle", () => {
          setIsMapLoaded(true);
        });

        google.maps.event.addListenerOnce(newMap, "tilesloaded", () => {
          // 초기 렌더링 이후, 타일까지 모두 로딩되었을때까지 로딩 UI를 표시하기 위함.
          setIsMapLoading(false);

          if (onGoogleApiLoaded) {
            onGoogleApiLoaded({ map: newMap });
          }
        });

        setMap(newMap);
      } catch (error) {
        setIsMapLoadError(
          error instanceof Error
            ? error
            : new Error("Failed to load Google Maps")
        );

        setIsMapLoading(false);
      }
    }
  }, [map, minZoom, onGoogleApiLoaded]);

  return { ref, isMapLoading, isMapLoaded, isMapLoadError };
}
