import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import TableHeaderFilter from "@sellernote/_sds-v2/src/components/table/TableHeaderFilter";
import { Table } from "@sellernote/_sds-v3/src/components/table";

import {
  FreeTimeContainer,
  FreeTimeError,
} from "../../api-interfaces/shipda-api/freeTime";
import ErrorGuide from "./ErrorGuide";
import useColumns from "./useColumns";
import useFilter from "./useFilter";
import useFreeTimeTableData from "./useFreeTimeTableData";
import Styled from "./index.styles";

type MetaType = { hasFilter: boolean };

const ROW_GROUP_SIZE = 3;

export default function FreeTimeTable({
  containers,
  isCombined,
  errorInfo,
}: {
  containers?: Array<FreeTimeContainer>;
  isCombined?: boolean;
  errorInfo?: FreeTimeError;
}) {
  const { columns } = useColumns();

  const {
    containerNoFilterOptions,
    filteredContainerNoList,
    handleFilterSelect,
  } = useFilter({ containers });

  const { rows } = useFreeTimeTableData({
    containers,
    isCombined,
    filteredContainerNoList,
  });

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      {containers && (
        <Styled.tableContainer className="free-time-table">
          <Table>
            <Table.Header>
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.HeaderGroup key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Table.HeaderColumn
                      key={header.id}
                      align={
                        header.column.columnDef.id === "price"
                          ? "right"
                          : "left"
                      }
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}

                      {(header.column.columnDef.meta as MetaType)
                        ?.hasFilter && (
                        <TableHeaderFilter<string>
                          filterType="multi"
                          onFiltersSelect={handleFilterSelect}
                          filterOptions={containerNoFilterOptions}
                          selectedFilters={filteredContainerNoList}
                          onFilterReset={() => {
                            handleFilterSelect([]);
                          }}
                        />
                      )}
                    </Table.HeaderColumn>
                  ))}
                </Table.HeaderGroup>
              ))}
            </Table.Header>

            <Table.Body>
              {table.getRowModel().rows.map((row, rowIndex) => (
                <Table.Row
                  key={row.id}
                  hasGroupBorder={!((rowIndex + 1) % ROW_GROUP_SIZE)}
                >
                  {row.getVisibleCells().map((cell) => {
                    return rowIndex % ROW_GROUP_SIZE &&
                      cell.id.includes("containerNo") ? (
                      <></>
                    ) : (
                      <Table.BodyCell
                        key={cell.id}
                        align={
                          cell.column.columnDef.id === "price"
                            ? "right"
                            : "left"
                        }
                        rowSpan={
                          cell.id.includes("containerNo")
                            ? ROW_GROUP_SIZE
                            : undefined
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.BodyCell>
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Styled.tableContainer>
      )}

      {errorInfo && <ErrorGuide errorInfo={errorInfo} />}
    </>
  );
}
