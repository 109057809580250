import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { css } from "styled-components";

import InfoOutlinedIcon from "@sellernote/_sds-v2/src/components/svgIcons/InfoOutlinedIcon";
import Tooltip from "@sellernote/_sds-v2/src/components/Tooltip";
import { TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import { FreightType } from "../../types/common/common";
import { RealTimeTransportInfo } from "../../types/forwarding/bid";
import { InfographicTransferData } from "../../types/forwarding/trello";
import { replaceEmptyToDash } from "../../utils/common/string";

import { COLOR } from "../../stylesToMoveToV1/constants";
import Styled from "./index.styles";

interface ShipmentTransferInfoProps {
  realtimeTransportInfo: RealTimeTransportInfo;
  isActiveInfo: boolean;
  freightType: FreightType;
  transferList: InfographicTransferData[] | undefined;
  className?: string;
}

export default function ShipmentTransferInfo({
  isActiveInfo,
  freightType,
  realtimeTransportInfo,
  transferList,
  className,
}: ShipmentTransferInfoProps) {
  const { t } = useTranslation();

  /** 환적 데이터가 있는지 여부 */
  const hasTransferShipment = transferList && transferList.length > 1;

  const isAirFreightType = freightType === "AIR";

  const { currentTransportName, currentTransportNumber } =
    realtimeTransportInfo;

  /** 현재 진행중인 환적항 하이라이트 index */
  const inProgressTransferShipmentIndex =
    transferList?.findIndex(({ shipNow }) => shipNow) ?? 0;

  /** 현재 운송이 내륙 운송인지 확인 */
  const isLandEventOngoing = (() => {
    if (!transferList) return false;

    return transferList[inProgressTransferShipmentIndex].shipName === "LTS";
  })();

  const transferShipmentTitle = useMemo(() => {
    if (!hasTransferShipment) return null;

    const transferTooltipList = transferList?.map(
      ({ shipName, voyageNo, portCode }, i) => {
        /** 출발지는 환적항이 없음 */
        if (i === 0)
          return `${replaceEmptyToDash(shipName)} / ${replaceEmptyToDash(
            voyageNo
          )}`;

        return `${replaceEmptyToDash(shipName)} / ${replaceEmptyToDash(
          voyageNo
        )} (${t(
          "page-mypage-bid:운송관리_상세_운송현황_환적항_타이틀"
        )} : ${portCode})`;
      }
    );

    return (
      <Tooltip
        className="custom-tooltip"
        position="topLeft"
        desc={transferTooltipList}
        {...(isActiveInfo && {
          tooltipBodyStyle: css`
            // 쉽다 어드민 대응
            z-index: 1400;

            .desc-list {
              color: ${COLOR.grayScale_600};
              & > .item:nth-child(${inProgressTransferShipmentIndex + 1}) {
                color: ${TEXT_COLOR.white_1};
              }
            }
          `,
        })}
      >
        <span className="more">
          &#91;
          <em className="more-text">
            {t("page-mypage-bid:운송관리_상세_운송현황_전체보기")}
          </em>
          &#93;
        </span>
      </Tooltip>
    );
  }, [
    hasTransferShipment,
    transferList,
    isActiveInfo,
    inProgressTransferShipmentIndex,
    t,
  ]);

  return (
    <Styled.freightTypeInfo
      className={`${className ? className : ""} transfer-info-container`}
    >
      <div className="info">
        {isLandEventOngoing ? (
          <p className="freight">
            {t("page-mypage-bid:운송관리_공통_상태_내륙운송중")}
          </p>
        ) : (
          <p className="freight">
            {isAirFreightType
              ? t("page-mypage-bid:운송관리_상세_운송현황_항공사명_타이틀")
              : t("page-mypage-bid:운송관리_상세_운송현황_모션명_타이틀")}
            : {currentTransportName}&nbsp;/&nbsp;
            {isAirFreightType
              ? t("page-mypage-bid:운송관리_상세_운송현황_편명_타이틀")
              : t("page-mypage-bid:운송관리_상세_운송현황_항차_타이틀")}
            : {currentTransportNumber}
          </p>
        )}

        {transferShipmentTitle || null}
      </div>

      {isActiveInfo && (
        <div className="cautions">
          <span className="title">
            {t("page-mypage-bid:운송관리_상세_운송현황_주의사항")}
          </span>
          <Tooltip
            position="topRight"
            desc={
              <Trans
                i18nKey={"page-mypage-bid:운송관리_상세_운송현황_주의사항_안내"}
              />
            }
            tooltipBodyStyle={css`
              // 쉽다 어드민 대응
              z-index: 1400;
            `}
          >
            <InfoOutlinedIcon
              width={12}
              height={12}
              color={TEXT_COLOR.black_2}
            />
          </Tooltip>
        </div>
      )}
    </Styled.freightTypeInfo>
  );
}
