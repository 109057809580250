import styled, { css } from "styled-components";

import { mobile } from "../../styles/functions";

const container = styled.div`
  /* staging환경에서만 내부용으로 사용되는 UI라 디자인시스템을 고려하지 않고 스타일링함 */
  z-index: 999999;
  position: fixed;
  padding: 8px 10px;
  border-radius: 10px;
  top: 4px;
  left: 4px;
  background-color: rgba(233, 0, 0, 0.5);
  cursor: default;
  color: #fff;
  font-size: 14px;

  ${mobile(css`
    font-size: 10px;
    padding: 4px 2px;
  `)}
`;

export default {
  container,
};
